import React from 'react'
import Footer_banner from '../images/Group_35.png'
import Logo from '../logo.png'
import { Link, useLocation, useNavigate } from 'react-router-dom';


const Footer = () => {
  const currentYear = new Date().getFullYear();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <>
      <footer className="footer pt-5" id="footer">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-12">
              <img src={Logo} alt="logo" style={{"width":"70px"}}/>
            </div>
            <div className="col-lg-12 mb-2 mt-2">
              <p className=''>High level experience in full stack developemet and quality work</p>
            </div>
            <div className="col-lg-12">
              <Link className="btn btn-md btn-primary px-5 rounded-5" to='/contact' onClick={scrollToTop}>Contact</Link>
            </div>
            <div className="col-lg-12 mt-5 mb-5">
              <a className='mx-1' href="https://www.linkedin.com/in/govind-rajput1/"><i className="fa-brands fa-linkedin-in"></i></a>
              <a className='mx-1' href="https://www.facebook.com/thecodebyte"><i className="fa-brands fa-facebook-f"></i></a>
              <a className='mx-1' href="https://www.instagram.com/thecodebyte/"><i className="fa-brands fa-instagram"></i></a>
              <a className='mx-1' href="#"><i className="fa-brands fa-youtube"></i></a>
            </div>
          </div>
        </div>
            <div className="container-fluid p-0 position-relative">
              <img src={Footer_banner} alt="footer" style={{"width":"100%"}}/>
              <p className='position-absolute bottom-0 start-0 px-4 text-black'> {currentYear} &copy; Developed by Govind || All Rights Reserved</p>
            </div>
      </footer>
    </>
  )
}

export default Footer
