// src/Chat.js
import React, { useState } from "react";
import useWebSocket from "react-use-websocket";

const Chat = ({ roomName }) => {
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);

    const { sendMessage, lastMessage } = useWebSocket(`ws://localhost:8000/ws/chat/${roomName}/`, {
        onMessage: () => {
            if (lastMessage !== null) {
                setMessages([...messages, lastMessage]);
            }
        },
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        sendMessage(JSON.stringify({ message }));
        setMessage("");
    };

    return (
        <div>
            <div>
                {messages.map((msg, index) => (
                    <p key={index}>{msg.data}</p>
                ))}
            </div>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <button type="submit">Send</button>
            </form>
        </div>
    );
};

export default Chat;
