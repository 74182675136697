import React from 'react'

const ScrollToTopButton = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
  return (
    <>
       <button onClick={scrollToTop} style={{position: 'fixed', bottom: '30px', right: '30px', color: '#ff6600', background: 'none', border: 'none', 'fontSize':'40px'}}>
        <i className="fa-solid fa-circle-chevron-up"></i>
        </button>
    </>
  )
}

export default ScrollToTopButton
