import React from 'react'
import about from '../images/about.webp'
import creative from '../images/creative.webp'
import responsive from '../images/responsive.webp'
import database from '../images/database.webp'
import { Link } from 'react-router-dom'

const About = () => {
    return (
        <>
            <section className='about' id='about'>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <img src={about} alt="about" width="100%" />
                            </div>
                            <div className="col-md-6">
                                <h4 className="orange">
                                    I'm a Developer
                                </h4>
                                <h2>
                                    I Can Create Any Website/Web Applications You Need!
                                </h2>
                                <p>
                                    Hello there! I'm a Full Stack Developer, and I'm very passionate and dedicated to my work.
                                    With experience as a professional Developer, I have acquired the skills
                                    and knowledge necessary to make your project a success. I enjoy every step of the development process,
                                    from discussion and collaboration.
                                </p>
                                <Link className="btn btn-md px-3 btn-primary rounded-5" to='/contact'>Hire Me</Link>
                            </div>
                        </div>
                    </div>
                <div className="container mt-5">
                    <div className="row gap-3">
                        <div className="col-lg creative-col">
                            <div className="card border-0">
                                <div className="align-self-center">
                                    <img src={creative} className="card-img-top icon" alt="creative designs" />
                                </div>
                                <div className="card-body text-center">
                                    <h5 className="card-title ">Creative Designs</h5>
                                    <p className="card-text"> Tailor the content and design of your website based on user preferences, behavior,
                                        or location to create personalized experiences that resonate with your audience and drive engagement.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg responsive-col">
                            <div className="card border-0">
                                <div className="align-self-center">
                                    <img src={responsive} className="card-img-top icon" alt="responsive design" />
                                </div>
                                <div className="card-body text-center">
                                    <h5 className="card-title ">Responsive</h5>
                                    <p className="card-text">Responsive design ensures websites adapt seamlessly to different screen sizes,
                                        providing optimal viewing experiences on various devices,
                                        improving user engagement and accessibility.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg datebase-col">
                            <div className="card border-0">
                                <div className="align-self-center">
                                    <img src={database} className="card-img-top icon" alt="database" />
                                </div>
                                <div className="card-body text-center">
                                    <h5 className="card-title ">Database</h5>
                                    <p className="card-text">Databases in websites store and manage data efficiently, enabling dynamic content delivery,
                                        user authentication, and personalized experiences for improved functionality and performance.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About
